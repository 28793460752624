.footer-svg {
  background-image: url("intro.svg");
  background-repeat: cover;
}

.footer-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.custom-shape-divider-top-1630012056 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1630012056 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 83px;
}

.custom-shape-divider-top-1630012056 .shape-fill {
  fill: #ffffff;
}

.footer-top-container {
  display: flex;
  flex-direction: row;
  padding-left: 69px;
  padding-right: 69px;
  align-items: center;
  border-bottom: 16px solid #413a59;
  width: 100%;
  align-items: flex-end;
}

.footer-top-left-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 33%;
  padding-bottom: 50px;
}

.footer-icons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 25px;
}

.footer-icon {
  margin-left: 15px;
  margin-right: 15px;
}

.footer-office-title {
  color: #055052;
  font-size: 30px;
  font-weight: bold;
}

.footer-office-description {
  color: #055052;
  font-size: 20px;
}

.footer-top-mid-container {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 33%;
  padding-bottom: 98px;
}

.footer-navigator-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 80%;
}

.footer-navigator {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  flex-direction: column;
}

.footer-link {
  color: #055052;
  font-size: 25px;
}

.nav-footer-links {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: 600;
  height: 100%;
  color: #055052;
}

.footer-top-right-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 33%;
}

.footer-logo {
  max-width: 240px;
}

.footer-bottom-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.footer-bottom-motto {
  font-size: 45px;
  font-weight: bold;
  color: #055052;
  margin-bottom: 19px;
  margin-top: 34px;
}

.ref-link {
  text-decoration: none;
  list-style: none;
  color: bisque;
}

.ref-link:hover {
  color: teal;
}

@media screen and (max-width: 960px) {
  .footer-top-container {
    display: flex;
    flex-direction: column-reverse;
    padding-left: 69px;
    padding-right: 69px;
    align-items: center;
    border-bottom: 16px solid #413a59;
    width: 100%;
    align-items: flex-end;
  }
  .footer-top-left-container {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .footer-top-mid-container {
    width: 100%;
  }

  .footer-top-right-container {
    display: none;
  }

  .footer-office-description {
    text-align: center;
  }

  .footer-bottom-motto {
    text-align: center;
  }

  .footer-navigator-container {
    flex-direction: column;
    align-items: center;
  }
  .footer-navigator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }  
}
