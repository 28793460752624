.product__intro-svg {
  background-image: url("../SVGFolder/intro.svg");
}

.product__hero-section {
  color: white;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.product__hero-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.product__row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.product__logo-container {
  width: 30%;
  padding-top: 10px;
  padding-bottom: 50px;
  justify-content: flex-end;
  display: flex;
}

.product__logo {
  width: 100%;
}

.product__col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 100%;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.product__hero-text-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 60px;
  text-align: center;
}

.product__top-line {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  color: #f00946;
  background-color: white;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
}

.product__heading {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #055052;
}

.product__dark {
  color: #1c2237;
}

.product__darkBg {
  background-color: #1c2237;
}

.product__hero-subtitle {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
}

@media screen and (max-width: 991px) {
  .product__container {
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .intro-svg {
    background-image: url("../SVGFolder/intro.svg");
  }
}

@media screen and (max-width: 900px) {
  .product__row {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .product__hero-text-wrapper {
    align-items: center;
    padding-bottom: 65px;
    text-align: center;
  }
  .product__logo-container {
    width: 50%;
  }
  .product__intro-svg {
    background-repeat: repeat;
    background-size: cover;
    height: 800px;
  }

  .product__col {
    align-items: center;
    justify-content: center;
    display: flex;
    max-width: 100%;
    flex-basis: 100%;
  }
}
