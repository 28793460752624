.intro-svg {
  background-image: url("../SVGFolder/intro.svg");
}

.home__hero-section {
  color: white;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.home__hero-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.logo-container {
  width: 30%;
  padding-top: 10px;
  padding-bottom: 50px;
  justify-content: flex-end;
  display: flex;
}

.logo {
  width: 100%;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapper {
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding-top: 0;
  padding-bottom: 60px;
}

.top-line {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  color: #f00946;
  background-color: white;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
}

.heading {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #055052;
}

.dark {
  color: #1c2237;
}

.darkBg {
  background-color: #1c2237;
}

.home__hero-subtitle {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
}

.home__hero-img-wrapper {
  justify-content: flex-end;
  display: flex;
  max-height: 400px;
}

.home__hero-img {
  max-width: 100%;
  max-height: 400px;
  margin-top: 0;
  margin-right: 0;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .intro-svg {
    background-image: url("../SVGFolder/intro.svg");
  }
}

@media screen and (max-width: 900px) {
  .row {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .home__hero-text-wrapper {
    align-items: center;
    padding-bottom: 65px;
    text-align: center;
  }
  .logo-container {
    width: 50%;
  }
  .intro-svg {
    background-repeat: repeat;
    background-size: cover;
    height: 1200px;
  }

  .col {
    align-items: center;
    justify-content: center;
    display: flex;
    max-width: 100%;
    flex-basis: 100%;
  }
}
