:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #ffff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--mobile {
  text-align: center;
  border-radius: 4px;
  width: 80%;
  text-decoration: none;
  font-size: 24px;
  background-color: transparent;
  color: #fff;
  padding: 14px 20px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 12px 64px;
  font-size: 20px;
}

.btn--wide {
  padding: 12px 64px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
}

.btn--wide:hover {
  color: #fff;
  background-color: #f00946;
  transition: all 0.2s ease-out;
}

.blue {
  color: #fff;
  background-color: #276afb;
  border: none;
}

.gradient-orange {
  color: #055052;
  background: linear-gradient(to left, #e47d6a, #e1ab2f);
  border: none;
}

.shadow {
  -webkit-box-shadow: -2px 5px 8px -3px rgba(0, 0, 0, 0.84);
  box-shadow: -2px 5px 8px -3px rgba(0, 0, 0, 0.84);
}

.gradient-orange:hover {
  color: wheat;
  background: #f00946;
  border: none;
}

.red {
  color: #fff;
  background-color: #f00946;
  border: none;
}

.primary {
  color: #fff;
  background-color: #242424;
  border: none;
}

.primary:hover {
  color: #242424;
  background-color: #fff;
  border: none;
}

.green {
  color: #fff;
  background-color: #25ce4a;
  border: none;
}

.green:hover {
  background-color: #242424;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
}
