.home__products-section-row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  padding-top: 30px;
  padding-bottom: 20px;
  justify-content: center;
}

.home__products-section-title {
  font-size: 40px;
  font-weight: bold;
  color: #055052;
}

.home__products-col {
  margin-bottom: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.home__product-container {
  max-width: 449px;
  overflow-wrap: break-word;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home__image-container {
  background-image: url("../SVGFolder/intro.svg");
  background-repeat: no-repeat;
  height: 275px;
  width: 100%;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  -webkit-box-shadow: -2px 5px 8px -3px rgba(0, 0, 0, 0.84);
  box-shadow: -2px 5px 8px -3px rgba(0, 0, 0, 0.84);
}

.home__product-bottom-container {
  padding-top: 15px;
  padding-bottom: 45px;
  flex-direction: row;
  display: flex;
}

.home_product-title {
  font-size: 32px;
  font-weight: bold;
  color: #055052;
}

.home__product-image {
  max-height: 249px;
  max-width: 177px;
}

.home__product-text-wrapper {
  width: 60%;
  padding-right: 15px;
}

.home__product-button-container {
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  width: 35%;
}

@media screen and (max-width: 900px) {
  .home__image-container {
    background-image: url("../SVGFolder/intro.svg");
    background-repeat: no-repeat;
    height: 275px;
    width: 80%;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    -webkit-box-shadow: -2px 5px 8px -3px rgba(0, 0, 0, 0.84);
    box-shadow: -2px 5px 8px -3px rgba(0, 0, 0, 0.84);
  }
  .home__products-col {
    max-width: 100%;
    flex-basis: 100%;
  }
  .home__product-container {
    max-width: 80%;
    overflow-wrap: break-word;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .home__product-bottom-container {
    padding-top: 15px;
    padding-bottom: 45px;
    flex-direction: column;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
  }

  .home__product-text-wrapper {
    width: auto;
  }
  .home__product-button-container {
    justify-content: flex-end;
    padding-top: 35px;
    width: auto;
  }
}

@media screen and (max-width: 700px) {
  .home__image-container {
    background-image: url("../SVGFolder/intro.svg");
    background-repeat: no-repeat;
    height: 275px;
    width: 100%;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    -webkit-box-shadow: -2px 5px 8px -3px rgba(0, 0, 0, 0.84);
    box-shadow: -2px 5px 8px -3px rgba(0, 0, 0, 0.84);
  }

  .home__product-container {
    flex-direction: row;
  }

  .home__product-container {
    max-width: 80%;
    overflow-wrap: break-word;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
