.svg {
  background-image: url("../SVGFolder/intro.svg");
}

.orangeBg {
  background-color: #fb9200;
}

.home__motto-section-row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  padding-top: 30px;
  padding-bottom: 20px;
  justify-content: center;
  flex-direction: row-reverse;
}

.home__motto-col {
  margin-bottom: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.home__motto-text-container {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.home__motto-image-container {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.home__motto-image {
  border-radius: 15;
}

.home__motto-title {
  color: #055052;
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 15px;
}

.home__motto-desc {
  color: #055052;
  font-size: 30px;
  font-weight: 500;
}

@media screen and (max-width: 900px) {
  .home__motto-col {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 0px;
  }

  .home__motto-text-container {
    padding-left: 70px;
    padding-right: 70px;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  .home__motto-desc {
    font-size: 25px;
  }
}
