.product__product-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.product__product-section-row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  padding-top: 30px;
  padding-bottom: 20px;
  justify-content: center;
}

.product__product-up-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.product__col-img {
  margin-bottom: 15px;
  flex: 1;
  max-width: 40%;
  flex-basis: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.product__col-text {
  margin-bottom: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  display: flex;
  flex-wrap: wrap;
}

.product__product-ingredients-text {
  font-size: 25px;
}

.product__product-image-container {
  max-width: 400px;
}

.product__product-ingredients-title {
  font-size: 50px;
  color: #055052;
  font-weight: bold;
}

.product__product-bottom-container {
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.product__product-title-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.product__product-title {
  color: #055052;
  font-size: 60px;
  font-weight: bold;
}

.product__product-desc-container {
  width: 100%;
}
.product__product-desc {
  font-size: 20px;
  text-align: left;
}

.product__product-backimg-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}

.product__product-backimg-title {
  font-size: 50px;
  color: #055052;
}

.product_product-backimg {
  width: 100%;
}

.product__bottom-ingredients {
  display: none;
}

.product__product-check-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

@media screen and (max-width: 900px) {
  .product__col-text {
    display: none;
  }
  .product__product-bottom-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .product__bottom-ingredients {
    display: inline-block;
  }

  .product__product-ingredients-title {
    padding-top: 20px;
    font-size: 30px;
  }
  .product__product-ingredients-text {
    font-size: 20px;
  }
  .product__product-title {
    font-size: 35px;
  }
  .product__product-backimg-container {
    padding-top: 20px;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .product__product-backimg-title {
    font-size: 30px;
    font-weight: bold;
  }

  .product__col-img {
    margin-bottom: 15px;
    flex: 1;
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .product__product-image-container {
    max-width: 600px;
  }
}
