.contact-svg {
  background-image: url("../SVGFolder/intro.svg");
  background-repeat: repeat;
}
.home__contact-section {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 50px;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home__contact-section-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1140px;
  padding-top: 20px;
  padding-bottom: 30px;
  border-radius: 15px;
}

.home__contact-section-row {
  display: flex;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  flex-wrap: wrap;
  align-content: stretch;
  flex-direction: row;
  width: 100%;
}

.home__contact-section-col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.contact-title {
  padding-left: 15px;
  padding-top: 35px;
  font-size: 40px;
  font-weight: bold;
  color: #055052;
}

.home__contact-left-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.messagebox {
  min-height: 250px;
  min-width: 100%;
  resize: none;
}

.input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  background-color: #ffeedb;
  color: black;
  outline-width: 0;
  border-radius: 5px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  font-weight: 500;
  opacity: 1; /* Firefox */
}

.submit-button {
  background-color: #3cbc8d;
  color: white;
  width: 100%;
  height: 40px;
  border: 0px;
  font-size: 15px;
  font-weight: bold;
}

.submit-button:hover {
  background-color: #f00946;
  transition: all 0.4s ease;
  transform: scale(1.1);
}

@media screen and (max-width: 960px) {
  .home__contact-section-col {
    max-width: 100%;
    flex-basis: 100%;
  }
}
