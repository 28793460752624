.navbar {
  background: transparent;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  padding-top: 20px;
  z-index: 999;
}

.navbar-menu-container {
  margin-right: 150px;
  width: 500px;
  background-color: #ffeedb;
  height: 50px;
  border-radius: 12px;
  align-items: center;
  display: flex;
  -webkit-box-shadow: -2px 5px 8px -3px rgba(0, 0, 0, 0.84);
  box-shadow: -2px 5px 8px -3px rgba(0, 0, 0, 0.84);
  justify-content: center;
  list-style: none;
}

.nav-item {
  border-bottom: 2px solid transparent;
  padding-left: 5px;
  padding-right: 5px;
}

.nav-item:hover {
  border-bottom: 2px solid #f00946;
  transform: scale(1.1);
  transition: all 0.3s ease;
}

.nav-links {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: 600;
  height: 100%;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.menu-icon-container {
  display: none;
}

.menu-icon {
  display: none;
}

.redBg {
  background-color: #f00946;
  border-radius: 10px;
}

.light {
  color: white;
}

.redText {
  color: #f00946;
}

@media screen and (max-width: 960px) {
  .navbar {
    align-items: center;
    justify-content: flex-end;
    display: flex;
    padding-right: 80px;
  }
  .navbar.active {
    background-color: #235da4;
    transition: all 0.5s ease;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .navbar-menu-container {
    padding-top: 50px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    border-radius: 0px;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .navbar-menu-container.active {
    background: #235da4;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    color: white;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #fff338;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .menu-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 50px;
    background-color: #ffeedb;
    -webkit-box-shadow: -2px 5px 8px -3px rgba(0, 0, 0, 0.84);
    box-shadow: -2px 5px 8px -3px rgba(0, 0, 0, 0.84);
    border-radius: 10px;
  }

  .menu-icon-container.active {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 50px;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .menu-icon {
    display: flex;
    position: relative;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    font-size: 2rem;
  }

  .redBg {
    background-color: #f00946;
    max-width: 300px;
    border-radius: 10px;
  }
}
