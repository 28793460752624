.home__about-section-row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
}

.home__about-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 230px;
  padding-right: 300px;
}

.home__about-picture-logo {
  height: auto;
  width: 400px;
}
.home__about-image {
  margin-top: 50px;
  height: auto;
  width: 683px;
  border-radius: 20px;
}

.home__about-text-container {
  padding-right: 200px;
  padding-left: 200px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.home__about-title {
  font-size: 50px;
  color: #055052;
  font-weight: bold;
}

.home__about-desc {
  color: #055052;
  font-size: 30px;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .home__about-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    padding-right: 60px;
  }
  .home__about-text-container {
    padding-right: 20px;
    padding-left: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .home__about-title {
    font-size: 40px;
    color: #055052;
    font-weight: bold;
  }
  .home__about-desc {
    color: #055052;
    font-size: 20px;
    text-align: center;
  }
}
